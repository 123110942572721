import { reactive, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import LanguageModel from './models/LanguageModel'

interceptAxios()
manageRouteAccess()
router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      try {
        // eslint-disable-next-line no-undef
        // $GLOBALSCRIPTS.execAll()
        window.scrollTo(0, 0)
      } catch (e) {
        console.log(e)
      }
    }, 100)
  }
})
const app = createApp(App)
app.config.globalProperties.$lang = reactive(new LanguageModel())
app.use(store).use(router)
app.mount('#app')

function interceptAxios () {
  // intercepting request
  axios.interceptors.request.use(function (config) {
    if (store.state.loggedInState && store.state.accessToken) {
      config.headers.Authorization = 'Bearer ' + store.state.accessToken
    }
    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })
  // intercepting responses
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response
  }, function (error) {
    // Do something with response error
    if (error && error.response && error.response.status === 401) {
      store.dispatch('unauthorizedAction')
    }
    return Promise.reject(error)
  })
}
function manageRouteAccess () {
  router.beforeEach((to, from, next) => {
    if (store.state.loggedInState) {
      if (['login', 'signup'].includes(to.name)) { next({ name: from.name }) }
    }
    if (to.meta.isPublic) {
      next()
    }
    if (store.state.loggedInState) {
      next()
    } else {
      next({ name: 'login' })
    }
  })
}
