import Config from './config'
var api = Config.api

const userAPIRoot = api.BASE_URL + '/user'
const getCurrentUserApi = userAPIRoot + '/current-user'
const refreshSuperAdminUserApi = userAPIRoot + '/SuperAdmin/refresh-token'
const refreshMomsiteUserApi = userAPIRoot + '/Momsite/refresh-token'

class UserService {
  getCurrentUser () {
    return api.getCall(getCurrentUserApi, api.DoNothing, api.ShowAlert)
  }

  refreshAdminUserAccessToken () {
    return api.postCall(refreshSuperAdminUserApi, api.NoPaylaod, api.DoNothing, api.ShowAlert)
  }

  refreshMomsiteUserAccessToken () {
    return api.postCall(refreshMomsiteUserApi, api.NoPaylaod, api.DoNothing, api.ShowAlert)
  }

  getAllUsers () {
    return api.getCall(userAPIRoot, api.DoNothing, api.ShowAlert)
  }

  getUserByUserType (UserType) {
    return api.getCall(userAPIRoot + '/' + UserType, api.DoNothing, api.ShowAlert)
  }

  getUserById (UserId) {
    return api.getCall(userAPIRoot + '/' + UserId, api.DoNothing, api.ShowAlert)
  }

  updateUser (UserModel) {
    return api.postCall(userAPIRoot, UserModel, api.ShowAlert, api.ShowAlert)
  }

  changeUserActiveStatus (ActiveStatus, UserId) {
    return api.postCall(userAPIRoot + '/' + UserId + '/update-status/' + ActiveStatus, api.NoPaylaod, api.ShowAlert, api.ShowAlert)
  }

  deleteUser (UserId) {
    return api.deleteCall(userAPIRoot + '/' + UserId, api.ShowAlert, api.ShowAlert)
  }
}
export default new UserService()
