import { createRouter, createWebHistory } from 'vue-router'

const SiteTitle = 'Garirzone-ADMIN | '
const routes = [
  {
    path: '/',
    name: 'AppTemplate',
    component: () =>
      import(/* webpackChunkName: 'AppLayout' */ '../components/Layout/AppLayout'),
    redirect: '/home',
    meta: {
      title: SiteTitle + 'Dashboard',
      isPublic: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: 'admin-dashboard' */ '../views/DashboardPage'),
        meta: {
          title: SiteTitle + 'Product',
          isPublic: true
        }
      },
      {
        path: 'product',
        name: 'admin-product',
        component: () =>
          import(/* webpackChunkName: 'admin-product' */ '../views/admin/Product'),
        meta: {
          title: SiteTitle + 'Product',
          isPublic: true
        }
      },
      {
        path: 'upload',
        name: 'admin-upload',
        component: () =>
          import(/* webpackChunkName: 'admin-upload' */ '../components/general/bulk.upload'),
        meta: {
          title: SiteTitle + 'upload',
          isPublic: true
        }
      },
      {
        path: 'property',
        name: 'admin-property',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Property'),
        meta: {
          title: SiteTitle + 'Property',
          isPublic: true
        }
      },
      {
        path: 'file',
        name: 'admin-file',
        component: () =>
          import(/* webpackChunkName: 'admin-file' */ '../views/admin/File'),
        meta: {
          title: SiteTitle + 'Files',
          isPublic: true
        }
      },
      {
        path: 'specification',
        name: 'admin-specification',
        component: () =>
          import(/* webpackChunkName: 'admin-specification' */ '../views/admin/Specification'),
        meta: {
          title: SiteTitle + 'Specification',
          isPublic: true
        }
      },
      {
        path: 'business',
        name: 'admin-business',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Business'),
        meta: {
          title: SiteTitle + 'Business',
          isPublic: true
        }
      },
      {
        path: 'offer',
        name: 'admin-offer',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Offer'),
        meta: {
          title: SiteTitle + 'Offer',
          isPublic: true
        }
      },
      {
        path: 'news',
        name: 'admin-news',
        component: () =>
          import(/* webpackChunkName: 'admin-news' */ '../views/admin/News'),
        meta: {
          title: SiteTitle + 'News',
          isPublic: true
        }
      },
      {
        path: 'brand',
        name: 'admin-brand',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Brand'),
        meta: {
          title: SiteTitle + 'Brand',
          isPublic: true
        }
      },
      {
        path: 'vehicleType',
        name: 'admin-vehicleType',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/VehicleType'),
        meta: {
          title: SiteTitle + 'VehicleType',
          isPublic: true
        }
      },
      {
        path: 'vehicleCategory',
        name: 'admin-vehicleCategory',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/VehicleCategory'),
        meta: {
          title: SiteTitle + 'vehicleCategory',
          isPublic: true
        }
      },
      {
        path: 'vehicleSubCategory',
        name: 'admin-vehicleSubCategory',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/VehicleSubCategory'),
        meta: {
          title: SiteTitle + 'vehicleSubCategory',
          isPublic: true
        }
      },
      {
        path: 'service',
        name: 'admin-service',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Service'),
        meta: {
          title: SiteTitle + 'service',
          isPublic: true
        }
      },
      {
        path: 'review',
        name: 'admin-review',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Review'),
        meta: {
          title: SiteTitle + 'review',
          isPublic: true
        }
      },
      {
        path: 'ads',
        name: 'admin-ads',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Ads'),
        meta: {
          title: SiteTitle + 'ads',
          isPublic: true
        }
      },
      {
        path: 'settings',
        name: 'admin-settings',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Settings'),
        meta: {
          title: SiteTitle + 'Settings',
          isPublic: true
        }
      },
      {
        path: 'aboutUs',
        name: 'admin-aboutUs',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/AboutUs'),
        meta: {
          title: SiteTitle + 'aboutUs',
          isPublic: true
        }
      },
      {
        path: 'faq',
        name: 'admin-faq',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Faq'),
        meta: {
          title: SiteTitle + 'faq',
          isPublic: true
        }
      },
      {
        path: 'termsAndConditions',
        name: 'admin-termsAndConditions',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/TermsAndConditions'),
        meta: {
          title: SiteTitle + 'termsAndConditions',
          isPublic: true
        }
      },
      {
        path: 'othersMenu',
        name: 'admin-othersMenu',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/OthersMenu'),
        meta: {
          title: SiteTitle + 'othersMenu',
          isPublic: true
        }
      },
      {
        path: 'adsSettings',
        name: 'admin-adsSettings',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/AdsSettings'),
        meta: {
          title: SiteTitle + 'adsSettings',
          isPublic: true
        }
      },
      {
        path: 'brochure',
        name: 'admin-brochure',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Brochure'),
        meta: {
          title: SiteTitle + 'brochure',
          isPublic: true
        }
      },
      {
        path: 'inquiry',
        name: 'admin-inquiry',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Inquiry'),
        meta: {
          title: SiteTitle + 'inquiry',
          isPublic: true
        }
      },
      {
        path: 'slider',
        name: 'admin-slider',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Slider'),
        meta: {
          title: SiteTitle + 'slider',
          isPublic: true
        }
      },
      {
        path: 'video',
        name: 'admin-video',
        component: () =>
          import(/* webpackChunkName: 'admin-property' */ '../views/admin/Video'),
        meta: {
          title: SiteTitle + 'video',
          isPublic: true
        }
      }
    ]
  },
  {
    path: '/signup',
    name: 'SignupTemplate',
    component: () =>
      import(/* webpackChunkName: 'LoginLayout' */ '../components/Layout/LoginLayout'),
    children: [
      {
        path: '',
        name: 'signup',
        component: () =>
          import(/* webpackChunkName: 'signup' */ '../views/SignupPage'),
        meta: {
          title: SiteTitle + 'Signup',
          isPublic: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginTemplate',
    component: () =>
      import(/* webpackChunkName: 'LoginLayout' */ '../components/Layout/LoginLayout'),
    children: [
      {
        path: '',
        name: 'login',
        redirect: { name: 'home' },
        component: () =>
          import(/* webpackChunkName: 'signup' */ '../views/SignupPage'),
        meta: {
          title: SiteTitle + 'Signup',
          isPublic: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
