import ApiHandler from './api-handler.service'

function getAPIHandler () {
  return ApiHandler
}

class Config {
  api = getAPIHandler();
}
export default new Config()
