<template>
  <router-view />
</template>

<script>
import store from './store'
import UserService from './services/user.service'

export default {
  name: 'App',
  data () {
    return {
      RefreshTokenInterval: process.env.VUE_APP_RefreshTokenIntervalInMinutes * 60 * 1000
    }
  },
  mounted () {
    //  this.$store.dispatch('loadSessionUser')
    //  this.initiateRefreshToken()
  },
  methods: {
    initiateRefreshToken: function () {
      const vm = this
      this.refreshToken()
      setInterval(() => {
        vm.refreshToken()
      }, this.RefreshTokenInterval)
    },
    refreshToken: function () {
      if (store.state.loggedInState) {
        UserService.refreshMomsiteUserAccessToken().then((response) => {
          if (response && response.data.Status) {
            this.$store.dispatch(
              'refreshAccessToken',
              response.data.Object.AccessToken
            )
          } else {
            this.$store.dispatch('unauthorizedAction')
          }
        })
      }
    }
  }
}
</script>
