import axios from 'axios'

class ApiHandler {
  BASE_URL = process.env.VUE_APP_API_BASE_URL;

  DoNothing = () => {};
  ShowAlert = null;
  NoPaylaod = null;

  /// On promise rejection event converted to error from catch exception
  rejectPromise (thrown) {
    if (axios.isCancel(thrown)) {
      return Promise.reject(thrown.message)
    } else {
      return Promise.reject(thrown)
    }
  }

  // Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  getCall (APIURL, onSuccess, onFail) {
    return axios.get(APIURL).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  // Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  deleteCall (APIURL, onSuccess, onFail) {
    return axios.delete(APIURL).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  // Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  putCall (APIURL, payload, onSuccess, onFail) {
    return axios.put(APIURL, payload).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  // Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  postCall (APIURL, payload, onSuccess, onFail) {
    return axios.post(APIURL, payload).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  postCallMultipartFormData (APIURL, payload, options, onSuccess, onFail) {
    return axios.post(APIURL, payload, options).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  // On success callback of an api call promise returned with alert displayed if no callback is sent
  eventSuccess (onSuccessCallback, response) {
    if (onSuccessCallback) { onSuccessCallback(response) } else { this.alertSuccess(response) }
    return Promise.resolve(response)
  }

  // On error callback of an api call promise returned with alert displayed if no callback is sent
  eventError (onFailCallback, error) {
    if (onFailCallback) { onFailCallback(error) } else { this.alertError(error.response.data.message || error) }
    return Promise.reject(error)
  }

  alertSuccess (response) {
    if (response.data.status) { alert(response.data.message, 'success') } else { this.alertError(response.data.message) }
  }

  alertError (error) {
    alert(error.message || error.toString(), 'danger')
  }
}
export default new ApiHandler()
