import UtilityService from '../services/utility.service'
export default class UserProfile {
  constructor () {
    this.id = 0
    this.IsEmailVerified = false
    this.FName = ''
    this.FullName = ''
    this.LName = ''
    this.RegDate = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.UsrType = 2
    this.VerifiedAt = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.companyName = ''
    this.email = ''
    this.phone = ''
  }

  load (data) {
    this.id = data.id
    this.IsEmailVerified = data.IsEmailVerified
    this.FName = data.FName
    this.FullName = data.FullName
    this.LName = data.LName
    this.RegDate = UtilityService.formatDateTime(data.RegDate, 'MM/DD/YY hh:mm A')
    this.UsrType = data.UsrType
    this.VerifiedAt = UtilityService.formatDateTime(data.VerifiedAt, 'MM/DD/YY hh:mm A')
    this.companyName = data.companyName
    this.email = data.email
    this.phone = data.phone
    return this
  }
}
