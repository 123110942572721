import Config from './config'
var api = Config.api

const translateAPIURL = 'https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=bn&dt=t&q='

class TranslatorService {
  toBangla (word) {
    return api.getCall(translateAPIURL + word, api.DoNothing, api.DoNothing)
  }
}
export default new TranslatorService()
