import { createStore } from 'vuex'
import router from '../router'

import AuthService from '../services/auth/auth.service'
import UserService from '../services/user.service'

import UserModel from '../models/UserModel'
import { reactive } from 'vue'

const keyGarirzoneStorage = 'GarirzoneAdminAccessToken'
const AccessToken = localStorage.getItem(keyGarirzoneStorage)

export default createStore({
  state: reactive({
    loggedInState: !!AccessToken,
    accessToken: AccessToken,
    userData: null
  }),
  actions: {
    loadSessionUser ({ commit }) {
      if (this.state.loggedInState) {
        UserService.getCurrentUser().then(
          response => {
            if (response.data.Status) {
              commit('setUserData', new UserModel().load(response.data.Object.user))
            }
          })
      }
    },
    login ({ commit }, loginModel) {
      return AuthService.login(loginModel).then(
        response => {
          if (response && response.data) {
            if (response.data.Status) {
              commit('setLoggedInStateAndToken', response.data.Object.accessToken)
            } else {
              commit('logout')
            }
          }
          return Promise.resolve(response)
        },
        error => {
          commit('logout')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }) {
      commit('logout')
    },
    refreshAccessToken ({ commit }, accessToken) {
      commit('setLoggedInStateAndToken', accessToken)
    },
    unauthorizedAction ({ commit }) {
      if (router.currentRoute.name !== 'login') {
        alert('Either session is expired or you are not authorized for the action. Please try login again.', 'warning')
        commit('logout')
        router.push({ name: 'login' })
      }
    }
  },
  mutations: {
    setUserData (state, userData) {
      state.userData = userData
    },
    setLoggedInStateAndToken (state, accessToken) {
      state.loggedInState = true
      state.accessToken = accessToken
      localStorage.setItem(keyGarirzoneStorage, accessToken)
    },
    logout (state) {
      localStorage.removeItem(keyGarirzoneStorage)
      state.loggedInState = false
      state.accessToken = null
    }
  },
  modules: {}
})
