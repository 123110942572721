import axios from 'axios'
import store from 'vuex'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const loginAPI = BASE_URL + '/login'
class AuthService {
  login (loginModel) {
    return axios
      .post(loginAPI, loginModel)
      .then(response => {
        return response
      })
  }

  logout () {
    store.dispatch('logout')
  }
}

export default new AuthService()
