import moment from 'moment'

class Util {
  formatDateTimeForDateTimeLocalForm (datetime) {
    return moment(datetime).format('YYYY-MM-DDThh:mm:ss.000')
  }

  formatDateTimeForUI (datetime) {
    return moment(datetime).format('MMM DD, YYYY - hh:mm A')
  }

  formatDateForUI (datetime) {
    return moment(datetime).format('MM/DD/YYYY hh:mm a')
  }

  formatInvoiceId (invoiceId) {
    var paddedInvId = ('00000000' + invoiceId).slice(-8)
    return 'INV-' + paddedInvId
  }

  formatDateTime (datetime, format) {
    return moment(datetime).format(format)
  }

  convertToJSDateTime (datetime) {
    return moment(datetime).toDate()
  }

  convertToBDTimeZone (datetime) {
    const dateTime = new Date(datetime)
    dateTime.setHours(dateTime.getHours() + 6)

    const bdtDateTimeString = this.formatDateTime(dateTime, 'MM/DD/YY hh:mm A')
    return bdtDateTimeString
  }
}
export default new Util()
