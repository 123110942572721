import Bangla from '../services/Language/bangla.json'
import English from '../services/Language/english.json'
import TranslatorService from '../services/translator.service'
export default class LanguageModel {
  LANGUAGES = {
    ENGLISH: 'ENG',
    BANGLA: 'বাংলা'
  }

  constructor () {
    this.lang = this.LANGUAGES.BANGLA
    this.source = Bangla
    this.UnavailablePhrases = {}
  }

  set (lang) {
    switch (lang) {
      case this.LANGUAGES.ENGLISH:
        this.lang = this.LANGUAGES.ENGLISH
        this.source = English
        return
      case this.LANGUAGES.BANGLA:
        this.lang = this.LANGUAGES.BANGLA
        this.source = Bangla
        return
      default:
        this.lang = this.LANGUAGES.English
        this.source = English
    }
  }

  replaceCommonPhrase (phrase) {
    phrase = phrase.replaceAll('0', '০')
      .replaceAll('1', '১')
      .replaceAll('2', '২')
      .replaceAll('3', '৩')
      .replaceAll('4', '৪')
      .replaceAll('5', '৫')
      .replaceAll('6', '৬')
      .replaceAll('7', '৭')
      .replaceAll('8', '৮')
      .replaceAll('9', '৯')
      .replaceAll('$', '৳')
      .replaceAll('ton', 'টন')
      .replaceAll('Ton', 'টন')
    return phrase
  }

  get (phrase) {
    phrase = phrase.trim()
    // if (!this.source[phrase]) {
    //   this.UnavailablePhrases[phrase] = phrase
    //   console.log(this.UnavailablePhrases)
    // }
    if (this.source[phrase]) {
      return this.source[phrase]
    } else {
      if (this.lang === this.LANGUAGES.BANGLA) {
        TranslatorService.toBangla(phrase).then(
          response => {
            this.UnavailablePhrases[response.data[0][0][1]] = response.data[0][0][0]
            console.log(this.UnavailablePhrases)
          }
        )
      }
      alert('\'' + phrase + '\'' + ' is not available in ' + this.lang + ' dictionary.', 'warning')
      return '{{' + phrase + '}}'
    }
  }
}
